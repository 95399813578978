/* Font Styles */

.font-primary {
    font-family: "Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

/* Font Sizes */

.font-size-10 { font-size: 10px; }

.font-size-12 { font-size: 12px; }

.font-size-14 { font-size: 14px; }

.font-size-16 { font-size: 16px; }

.font-size-18 { font-size: 18px; }

.font-size-20 { font-size: 20px; }

/* Font Weights */

.font-weight-100 { font-weight: 100; }

.font-weight-200 { font-weight: 200; }

.font-weight-300 { font-weight: 300; }

.font-weight-400 { font-weight: 400; }

.font-weight-500 { font-weight: 500; }

.font-weight-600 { font-weight: 600; }

.font-weight-700 { font-weight: 700; }

.font-weight-800 { font-weight: 800; }

.font-weight-900 { font-weight: 900; }
