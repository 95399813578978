@import "./fontHelpers.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animated-card-border {
  height: 3px;
  background: #fff;
}

.animated-card-border:after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #4286f7;
  -webkit-transition: width 0.33s;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  border-radius: 2px;
}

.card:hover .animated-card-border:after {
  width: 100%;
}

.badge {
  display: inline-block;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.125rem;
}

.badge-success {
  background-color: rgb(198, 246, 213);
  color: rgb(34, 84, 61);
}

.badge-danger {
  background-color: rgb(254, 215, 215);
  color: rgb(130, 39, 39);
}

.badge-default {
  background-color: rgb(237, 242, 247);
  color: rgb(26, 32, 44);
}

.badge-featured {
  position: absolute;
  font-weight: 700;
  left: 10px;
  top: 10px;
  /* width: 65px; */
  height: 15px;
  background-color: #fff;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 0.125rem;
  text-shadow: 1px 1px 2px #d9d9d9;
  cursor: pointer;
}

.nav-link {
  padding: 0.7em;
  width: 100%;
  text-decoration: none;
  font-family: "'Exo 2'; sans-serif";
  font-size: 1.15em;
  color: #f1f1f1;
  font-weight: 600;
  text-shadow: 1px 1px black;
  background-color: black;
}
